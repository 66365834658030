<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.roteiro_motivo_cancelamento.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.roteiro_motivo_cancelamento.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('RoteiroMotivoCancelamento', 'Inserir')"
      :sem-filtro="!buscarPermissao('RoteiroMotivoCancelamento', 'Listar')"
      @abrirNovo="abrirNovo"
      @filtrar="filtrar"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>
    <botao-padrao
      v-if="podeExcluir && buscarPermissao('RoteiroMotivoCancelamento', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>
    <tabela-padrao
      v-model="tabela.selecionados"
      :dados="tabela.dados"
      class="mt-2"
      :mostrar-checkbox="buscarPermissao('RoteiroMotivoCancelamento', 'Deletar')"
      :colunas="tabela.colunas"
      :por-pagina="tabela.porPagina"
      :quantidade-paginas="tabela.quantidadePaginas"
      @paginar="listarRegistros"
    >
      <!-- eslint-disable vue/valid-v-slot-->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="buscarPermissao('RoteiroMotivoCancelamento', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item

              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import RoteiroMotivoCancelamentoService from '@common/services/cadastros/RoteiroMotivoCancelamentoService.js';
import Filtro from './filtro.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro
  },
  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'nome',
            text: this.$t('modulos.roteiro_motivo_cancelamento.tabela.nome'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 0,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },

    };
  },
  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },
  mounted() {
    this.listarRegistros();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.roteiro_motivo_cancelamento.titulos.listagem')
    );
  },
  created(){
   helpers.redirecionarSemPermissao(this, 'RoteiroMotivoCancelamento', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    listarRegistros: function (paginaAtual = 1, porPagina = 10) {
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      RoteiroMotivoCancelamentoService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data.items;
          this.tabela.quantidadeItens = res.data.totalItems;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;

        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    excluirRegistros: function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');
        const listaExclusaoIds = this.tabela.selecionados.map((item) => item.id);
        RoteiroMotivoCancelamentoService.excluir(listaExclusaoIds)
          .then(() => {
            this.listarRegistros();
            this.toastSucesso(this.$t(`modulos.roteiro_motivo_cancelamento.exclusao_sucesso`));
            this.tabela.selecionados = []
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    abrirNovo: function () {
      this.$router.push({ name: 'motivo-cancelamento-roteiro-novo' });
    },
    abrirEditar: function (item) {
      this.$router.push({
        name: 'motivo-cancelamento-roteiro-editar',
        params: { id: item.id },
      });
    },
  },
};
</script>
